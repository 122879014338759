import classNames from "classnames";
import Link from "next/link";
import { Card } from "../Card";
import { getAspectRatioClass } from "~/utils/getAspectRatioClass";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getOfferDetails } from "~/utils/getOfferDetails";
import { Badge } from "../Backroom/Badge";
import { StrapiProduct } from "~/shared-types";
import { getRoute } from "~/utils/getRoute";

type Props = {
  product: StrapiProduct;
  onImageLoad?: () => void;
};

export const ProductCardImage = ({ product, onImageLoad }: Props) => {
  const { images, featuredImageOrientation } = product.attributes;
  const { hasCurrentOffer, offerTitle } = getOfferDetails(product);
  const showSoldOut = product.attributes.stockStatus === "notInStock";
  const showOffer = hasCurrentOffer && !showSoldOut;
  if (!images.data?.length) return null;

  const [image] = images.data;

  const aspectRatioClass = getAspectRatioClass(image, featuredImageOrientation);

  const linkToProduct = getRoute("/products/:productId", {
    productId: product.id,
  });

  return (
    <div className={classNames("relative w-full", aspectRatioClass)}>
      <Link href={linkToProduct}>
        <Card.Image
          {...getFormattedImageAttributes(image, "large", {
            altFallback: product.attributes.name,
          })}
          className={classNames(
            "absolute w-full object-cover",
            aspectRatioClass
          )}
          onLoad={onImageLoad}
          loading={"lazy"}
        />
      </Link>
      <div className="absolute top-2 left-4 flex items-start">
        {showSoldOut && <Badge color="soldOut">{"Solgt"}</Badge>}
        {showOffer && (
          <Badge color="yellow" className="line-clamp-1 break-all">
            {offerTitle}
          </Badge>
        )}
      </div>
    </div>
  );
};
